<template>
    <div>
        <Navbar/>
        <b-container class="mt-5">
            <b-row v-if="menuPrincipal">
                <b-col class="my-auto" cols="12" sm="12" md="12" lg="6">
                    <b-row>
                        <b-col class="mb-3" cols="12" sm="12" md="6" lg="6">
                            <b-button @click="mostrarUsuarios('1')" variant="bg_primary" class="btn_menu_option py-4 btn-block text-uppercase">Administrador (a)</b-button>
                        </b-col>
                        <b-col class="mb-3" cols="12" sm="12" md="6" lg="6">
                            <b-button @click="mostrarUsuarios('3')" variant="bg_primary" class="btn_menu_option py-4 btn-block text-uppercase">Médico</b-button>
                        </b-col>
                    </b-row>
                    <b-row >
                        <b-col class="mb-3" cols="12" sm="12" md="6" lg="6">
                            <b-button @click="mostrarUsuarios('4')" variant="bg_primary" class="btn_menu_option py-4 btn-block text-uppercase">Enfermero</b-button>
                        </b-col>
                        <b-col class="mb-3" cols="12" sm="12" md="6" lg="6">
                            <b-button @click="mostrarUsuarios('2')" variant="bg_primary" class="btn_menu_option py-4 btn-block text-uppercase">Recepcionista</b-button>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col class="p-auto m-auto d-none d-md-block d-lg-block" cols="12" sm="12" md="6" lg="6">
                    <div class="pl-5 pb-5" style="width: auto; margin: 0 auto 0 auto;">
                        <b-img :src="require('../../../assets/img/iconoRegistro.png')" style="max-height: 100%; max-width: 100%;"/>
                    </div>
                </b-col>
            </b-row>

            <!-- Menú para ver los diferentes tipos de usuarios -->
            <b-row v-if="verUsuarios">
                <b-col class="mx-auto" cols="11" sm="11" md="11" lg="3">
                    <b-row>
                        <button id="admin" @click="obtenerUsuarios('1')" class="btn_submenu_users_pressed btn_submenu_normal py-4 mb-4 text-center btn_radius btn-block">
                            ADMINISTRADOR (A)
                        </button>
                        <button id="enfermero" @click="obtenerUsuarios('4')" class="btn_submenu_normal py-4 mb-4 text-center btn_radius btn-block">
                            ENFERMERO (A)
                        </button>
                        <button id="medico" @click="obtenerUsuarios('3')" class="btn_submenu_normal py-4 mb-4 text-center btn_radius btn-block">
                            MÉDICO
                        </button>
                        <!-- <button id="especialista" @click="obtenerUsuarios('5')" class="btn_submenu_normal py-4 mb-4 text-center btn_radius btn-block">
                            ESPECIALISTA
                        </button> -->
                        <button id="resepcionista" @click="obtenerUsuarios('2')" class="btn_submenu_normal py-4 mb-4 text-center btn_radius btn-block">
                            RECEPCIONISTA
                        </button>
                    </b-row>
                </b-col>
                <b-col cols="12" sm="12" md="12" lg="9">
                    <b-container> 
                        <b-skeleton-wrapper class="p-0 m-0" :loading="loading">
                            <template #loading>
                                <b-row>
                                    <b-col v-for="item of skeleton" :key="item.id" class="mb-3" cols="12" sm="12" md="4" lg="4">
                                        <b-skeleton class="size btn-block border-0" type="input"></b-skeleton>
                                    </b-col>
                                </b-row>
                            </template>

                            <b-row>
                                <b-col v-for="item of allUser" :key="item.id" class="mb-3" cols="12" sm="12" md="4" lg="4">
                                    <div @click="verUsuario(item)" class="input-group flex-nowrap cursor" v-bind:title="item.nombre">
                                        <span class="input-group-text" id="img" align="center">
                                            <i class="fa fa-user fa-2x"></i>
                                        </span>
                                        <p type="text" class="form-control contenedor bg_third" id="contenido">{{item.nombre}}<br>{{item.cedula}}</p>
                                    </div>
                                </b-col>

                            </b-row>
                            <div class="overflow-auto mt-4" v-if="usuarios.length>18">
                                <div class="mt-3">
                                    <b-pagination v-model="currentPage" :per-page="perPage" :total-rows="rows" align="center"></b-pagination>
                                </div>
                            </div>
                        </b-skeleton-wrapper>
                    </b-container>
                </b-col>
            </b-row>
            
            <b-row v-if="crearUsuario">
                <b-col class="mx-auto" cols="11" sm="11" md="11" lg="3">
                    <b-row>
                        <button id="crearUsuario1" @click="agregarCampo('1')" class="btn_submenu_users_pressed btn_submenu_normal py-4 mb-4 text-center btn_radius btn-block">
                            ADMINISTRADOR (A)
                        </button>
                        <button id="crearUsuario4" @click="agregarCampo('4')" class="btn_submenu_normal py-4 mb-4 text-center btn_radius btn-block">
                            ENFERMERO (A)
                        </button>
                        <button id="crearUsuario3" @click="agregarCampo('3')" class="btn_submenu_normal py-4 mb-4 text-center btn_radius btn-block">
                            MÉDICO
                        </button>
                        <!-- <button id="crearUsuario5" @click="agregarCampo('5')" class="btn_submenu_normal py-4 mb-4 text-center btn_radius btn-block">
                            ESPECIALISTA
                        </button> -->
                        <button id="crearusuario2" @click="agregarCampo('2')" class="btn_submenu_normal py-4 mb-4 text-center btn_radius btn-block">
                            RECEPCIONISTA
                        </button>
                    </b-row>
                </b-col>

                <b-col class="px-5" cols="12" sm="12" md="12" lg="9">
                    <h3 class="txt_secondary text-uppercase mb-3">Registro de usuario</h3>
                    <b-table-simple class="table-sm">
                        <b-tbody>  
                            <b-tr>
                                <b-th class="th_style">Nombre</b-th>
                                <b-td class="border_table">
                                    <b-form-input v-model="datosUsuario.nombre" autocomplete="off" v-on:keypress="soloLetras($event)" :state="nombre" class="border-0" size="sm" placeholder="ingrese el nombre"/>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">Numero de teléfono</b-th>
                                <b-td class="border_table">
                                    <b-form-input v-model="datosUsuario.celular" autocomplete="off" maxlength="10" v-on:keypress="soloNumeros($event)" :state="celular" class="border-0" size="sm" placeholder="ingrese numero de teléfono celular"/>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">Email</b-th>
                                <b-td class="border_table">
                                    <b-form-input v-model="datosUsuario.email" autocomplete="off" :state="email" class="border-0" size="sm" placeholder="ingrese correo"/>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">Perfil de usuario</b-th>
                                <b-td class="border_table">
                                    <b-form-select disabled v-model="datosUsuario.perfil_id" :options="perfiles" @change="change(datosUsuario.perfil_id)" class="border-0 disabled_select" size="sm"/>
                                </b-td>
                            </b-tr>
                            <b-tr  v-if="datosUsuario.perfil_id==3 || datosUsuario.perfil_id==4" >
                                <b-th class="th_style">Cédula general</b-th>
                                <b-td class="border_table">
                                    <b-form-input autocomplete="off" v-model="datosUsuario.cedula" :state="cedula" maxlength="15" minlength="8" class="border-0" size="sm" placeholder="ingrese cédula"/>
                                </b-td>
                            </b-tr>
                            <b-tr v-if="datosUsuario.perfil_id==3 || datosUsuario.perfil_id==4">
                                <b-th class="th_style">Especialidad</b-th>
                                <b-td class="border_table">
                                    <b-form-select v-model="datosUsuario.especialidad_id" :options="especialidades" class="border-0" size="sm"/>
                                </b-td>
                            </b-tr>
                            <b-tr  v-if="datosUsuario.especialidad_id != null" >
                                <b-th class="th_style">Cédula especialidad</b-th>
                                <b-td class="border_table">
                                    <b-form-input v-model="datosUsuario.especialidad_cedula" :state="cedulaEspecialidad" autocomplete="off" class="border-0" size="sm" placeholder="ingrese cédula de especialidad"/>
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                    <b-row v-if="error">
                        <b-col cols="12">
                            <div class="contenedor-de-errores pt-3 pb-1">
                                <ul class="lista-errores px-2">
                                    <li v-for="(value,key) in errores" :key="key.value"><i class="fa fa-warning px-1 icon-errror" />| {{value[0]}} <br></li>
                                </ul>
                            </div>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>

            <div v-if="agregarUsuario" class="d-flex justify-content-end mt-5 mb-5">
                <b-col cols="12" sm="12" md="5" lg="5">
                    <div class="d-flex mb-4">
                        <span class="fa fa-user-plus fa-lg my-auto px-3 icon_color"></span>
                        <button class="btn_formluario border-0 btn-block py-2 txt_white text-uppercase" @click="btnCrearUsuario()">Crear nuevo usuario</button>
                    </div>
                </b-col>
            </div> 

            <div v-if="btnGuardarUsuario" class="d-flex justify-content-end mt-5">
               <span class="fa fa-save fa-lg my-auto px-3 icon_color"></span>
                <b-col cols="12" sm="12" md="4" lg="4">
                    <b-overlay
                    :show="busy"
                    rounded
                    opacity="0.6"
                    spinner-small
                    spinner-variant="primary"
                    >
                        <button class="btn_formluario border-0 btn-block py-2 txt_white" :disabled="busy" @click="addUser()">GUARDAR</button>
                    </b-overlay>
                </b-col>
            </div> 
            <div v-if="btnVerUsuarios" class="d-flex justify-content-end mt-2 mb-5">
                <span class="fa fa-users fa-lg my-auto px-3 icon_color"></span>
                <b-col cols="12" sm="12" md="4" lg="4">
                    <button @click="regresarVerUsuarios()" class="btn_formluario border-0 btn-block py-2 txt_white text-uppercase" >Ver usuarios</button>
                </b-col>
            </div> 
        </b-container>
        <b-button @click="btnFlotante()" v-if="botonFlotante" variant="btn_red" class="float-btn-back pt-1 d-none d-sm-none d-md-block d-lg-block">
            <span class="fa fa-arrow-left my-float-btn"></span>
        </b-button>
        <Success :abrir="openSuccess" @cerrarModal="cerrarModal()"/>
        <Error :abrir="openError" @cerrarModalError="cerrarModalError()"/>
        <DeleteSuccess :abrir="openDeleteSuccess" @cerrarModalDeleteSuccess="cerrarModalDeleteSuccess()"/>
        <!-- <component v-if="showUser" v-bind:is="current"></component> -->
        <VerUsuario ref="verUsuario" v-show="showUser" @volverAtras="volverAtras()" @usuario_eliminado="usuario_eliminado()" @updated="updated()"/>
    </div>
</template>

<script>
import Vue from 'vue'
import Navbar from "@/components/Navbar.vue"
import VerUsuario from "@/components/Administrador/VerUsuario.vue"
import DeleteSuccess from "@/components/Modals/DeleteSuccess.vue"

import { url } from "../../../../settings";
import Success from "@/components/Modals/Success.vue";
import Error from "@/components/Modals/Error.vue";

export default {
    name: 'Administrador',
    components: { Navbar, Success, Error, VerUsuario, DeleteSuccess },

    data:() => ({
        busy: false,
        currentPage: 1,
        perPage:18,
        openDeleteSuccess: false,
        showUser: false,
        current: "VerUsuario",
        error:false,
        errores:"",
        especialidades:[],
        perfiles:[],
        usuarios:[],
        p1:[],
        p2:[],
        p3:[],
        p4:[],
        p5:[],
        menuPrincipal: true,
        btnVerUsuarios: false,
        verUsuarios: false,
        crearUsuario: false,
        botonFlotante: false,
        openSuccess:false,
        openError:false,
        
        agregarUsuario: false,
        btnGuardarUsuario: false,

        agregarEspecialidad: false,

        loading: true,
        skeleton: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15],

        datosUsuario: {
            nombre:"",
            email:"",
            celular:"",
            perfil_id:1,
            especialidad_id:null,
            especialidad_cedula: '',
            cedula:"",
        },

        errors: []
    }),

    computed: {
        nombre() {
            return this.datosUsuario.nombre.length >= 10
        },

        celular() {
            return this.datosUsuario.celular.length === 10 ? true : false
        },

        email() {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(this.datosUsuario.email)
        },

        cedula() {
            let flag = false
            if (this.datosUsuario.cedula.length >= 7 && this.datosUsuario.cedula.length <= 15) {
                flag = true
            }
            return flag
        },

        cedulaEspecialidad() {
            let flag = false
            if (this.datosUsuario.especialidad_cedula.length >= 7) {
                flag = true
            }
            return flag
        },

        allUser() {
            const items = this.usuarios;
            // Return just page of items needed
            return items.slice(
                (this.currentPage - 1) * this.perPage,
                this.currentPage * this.perPage
            )
        },

        rows() {
            return this.usuarios.length
        },

    },

    methods: {
        verUsuario(user) {
            setTimeout(() => {
                this.$refs.verUsuario.DatosUsuario(user)
            }, 100);
            
            this.verUsuarios = false;
            this.agregarUsuario = false
            this.showUser = true;
            this.botonFlotante= false;
        },

        soloNumeros(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },

        soloLetras(evt) {
            let char = String.fromCharCode(evt.keyCode);
            if(/^[A-Za-z-zA-ZÀ-ÿ,\u00f1\u00d1 ]+$/.test(char)) {
                return true;
            } else {
                evt.preventDefault();
            }
        },

        change(){
            this.datosUsuario.especialidad_id=null
            this.datosUsuario.cedula=""
        },
        
        mostrarUsuarios(value) {
            this.menuPrincipal = false;
            this.agregarUsuario = false;
            this.verUsuarios = true;
            this.agregarUsuario = true;
            this.botonFlotante = true;
            switch (value) {
                case '1':
                    setTimeout(() => {
                        this.obtenerUsuarios('1')
                    }, 200);
                    break;

                case '2':
                    setTimeout(() => {
                        this.obtenerUsuarios('2')
                    }, 200);
                    break

                case '3':
                    setTimeout(() => {
                        this.obtenerUsuarios('3')
                    }, 200);
                    break;

                case '4':
                    setTimeout(() => {
                        this.obtenerUsuarios('4')
                    }, 200);
                    break;

                default:
                    break;
            }
        },

        obtenerUsuarios(value) {
            const u1 = document.getElementById('admin');
            const u4 = document.getElementById('enfermero');
            const u3 = document.getElementById('medico');
            // const u5 = document.getElementById('especialista');
            const u2 = document.getElementById('resepcionista');
            switch (value) {
                case '1':
                    u1.classList.add('btn_submenu_users_pressed');
                    u4.classList.remove('btn_submenu_users_pressed');
                    u3.classList.remove('btn_submenu_users_pressed');
                    // u5.classList.remove('btn_submenu_users_pressed');
                    u2.classList.remove('btn_submenu_users_pressed');
                    this.usuarios=this.p1
                    break;

                case '2':
                    u4.classList.remove('btn_submenu_users_pressed');
                    u2.classList.add('btn_submenu_users_pressed');
                    u3.classList.remove('btn_submenu_users_pressed');
                    // u5.classList.remove('btn_submenu_users_pressed');
                    u1.classList.remove('btn_submenu_users_pressed');
                    this.usuarios=this.p2
                    break;
                
                case '3':
                    u1.classList.remove('btn_submenu_users_pressed');
                    u4.classList.remove('btn_submenu_users_pressed');
                    u3.classList.add('btn_submenu_users_pressed');
                    // u5.classList.remove('btn_submenu_users_pressed');
                    u2.classList.remove('btn_submenu_users_pressed');
                    this.usuarios=this.p3
                    break;
                
                case '4':
                    u1.classList.remove('btn_submenu_users_pressed');
                    u2.classList.remove('btn_submenu_users_pressed');
                    u3.classList.remove('btn_submenu_users_pressed');
                    u4.classList.add('btn_submenu_users_pressed');
                    // u5.classList.remove('btn_submenu_users_pressed');
                    this.usuarios=this.p4
                    break;

                case '5':
                    u1.classList.remove('btn_submenu_users_pressed');
                    u2.classList.remove('btn_submenu_users_pressed');
                    u3.classList.remove('btn_submenu_users_pressed');
                    // u5.classList.add('btn_submenu_users_pressed');
                    u4.classList.remove('btn_submenu_users_pressed');
                    this.usuarios=this.p5
                    break;
            
                default:
                    break;
            }
        },

        btnFlotante() {
            this.verUsuarios = false;
            this.agregarUsuario = false;
            this.botonFlotante = false;
            this.menuPrincipal = true;
        },

        regresarVerUsuarios() {
            this.crearUsuario = false;
            this.btnGuardarUsuario = false;
            this.btnVerUsuarios = false;
            this.agregarUsuario = true;
            this.verUsuarios = true;
            this.botonFlotante = true;
        },

        btnCrearUsuario() {
            this.verUsuarios = false;
            this.agregarUsuario = false;
            this.botonFlotante = false;
            this.btnGuardarUsuario = true;
            this.btnVerUsuarios = true;
            this.crearUsuario = true;
        },

        agregarCampo(value) {
            const u1 = document.getElementById('crearUsuario1');
            const u4 = document.getElementById('crearUsuario4');
            const u3 = document.getElementById('crearUsuario3');
            // const u5 = document.getElementById('crearUsuario5');
            const u2 = document.getElementById('crearusuario2');
            this.datosUsuario.perfil_id=value;
            switch (value) {
                case '1':
                    u1.classList.add('btn_submenu_users_pressed');
                    u4.classList.remove('btn_submenu_users_pressed');
                    u3.classList.remove('btn_submenu_users_pressed');
                    // u5.classList.remove('btn_submenu_users_pressed');
                    u2.classList.remove('btn_submenu_users_pressed');
                    this.agregarEspecialidad = false;
                    this.datosUsuario.especialidad_id=null
                    this.datosUsuario.cedula=""
                    break;

                case '2':
                    u4.classList.remove('btn_submenu_users_pressed');
                    u2.classList.add('btn_submenu_users_pressed');
                    u3.classList.remove('btn_submenu_users_pressed');
                    // u5.classList.remove('btn_submenu_users_pressed');
                    u1.classList.remove('btn_submenu_users_pressed');
                    this.agregarEspecialidad = false;
                    this.datosUsuario.especialidad_id=null
                    this.datosUsuario.cedula=""
                    break;
                
                case '3':
                    u1.classList.remove('btn_submenu_users_pressed');
                    u4.classList.remove('btn_submenu_users_pressed');
                    u3.classList.add('btn_submenu_users_pressed');
                    // u5.classList.remove('btn_submenu_users_pressed');
                    u2.classList.remove('btn_submenu_users_pressed');
                    this.agregarEspecialidad = true;
                    break;
                
                case '4':
                    u1.classList.remove('btn_submenu_users_pressed');
                    u2.classList.remove('btn_submenu_users_pressed');
                    u3.classList.remove('btn_submenu_users_pressed');
                    u4.classList.add('btn_submenu_users_pressed');
                    // u5.classList.remove('btn_submenu_users_pressed');
                    this.agregarEspecialidad = true;
                    break;

                case '5':
                    u1.classList.remove('btn_submenu_users_pressed');
                    u2.classList.remove('btn_submenu_users_pressed');
                    u3.classList.remove('btn_submenu_users_pressed');
                    // u5.classList.add('btn_submenu_users_pressed');
                    u4.classList.remove('btn_submenu_users_pressed');
                    this.agregarEspecialidad = false;
                    this.datosUsuario.especialidad_id=null
                    this.datosUsuario.cedula=""
                    break;
            }
        },

        async obtenerEspecialidades() {
            try {
                const res = await fetch(`${url}/api/especialidades`, {
                    method: 'get',
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                        "X-Requested-With": "XMLHttpRequest",
                        "Authorization": "Bearer "+ localStorage.getItem("token")
                    }
                });
                if (!res.ok) {
                    const message = `An error has occured: ${res.status} - ${res.statusText}`;
                    throw new Error(message);
                }

                const data = await res.json();
                this.especialidades = data.especialidades.map(item => {
                    return {
                        value: item.id,
                        text: item.nombre
                    };
                });
                this.especialidades.unshift( { value: null, text: 'Sin especialidad' },)

            } catch (error) {
                console.log(error);
            }
        },

        async obtenerPerfiles() {
            try {
                const res = await fetch(`${url}/api/perfiles`, {
                    method: 'get',
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                        "X-Requested-With": "XMLHttpRequest",
                        "Authorization": "Bearer "+ localStorage.getItem("token")
                    }
                });
                if (!res.ok) {
                    const message = `An error has occured: ${res.status} - ${res.statusText}`;
                    throw new Error(message);
                }

                const data = await res.json();
                this.perfiles = data.perfiles.map(item => {
                    return {
                        value: item.id,
                        text: item.nombre
                    };
                });
                this.perfiles.unshift( { value: null, text: 'Seleccione' },)

            } catch (error) {
                console.log(error);
            }
        },

        async addUser() {
            this.busy = true;
            this.error=false
            this.errores=[]
            console.log(this.datosUsuario)
            try {
                const res = await fetch(`${url}/api/usuarios/store`, {
                    method: 'post',
                    headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                    "X-Requested-With": "XMLHttpRequest",
                    'Authorization': "Bearer "+ localStorage.getItem("token"),
                    },
                    body: JSON.stringify(this.datosUsuario),
                });
                const data = res.json();
                Vue.prototype.$error500 = res.status===500
                console.log(data);
                var warn=false
                var promise = Promise.resolve(data);
                var err=[]
                await promise.then(function(val) {
                    console.log(val);
                    if (val.class=="success") {
                    warn=true
                    }else{
                        err=val.errors
                    }
                });
                    if (warn) {
                    
                        this.regresarVerUsuarios();
                        this.datosUsuario.nombre=""
                        this.datosUsuario.email=""
                        this.datosUsuario.celular=""
                        this.datosUsuario.especialidad_id="",
                        this.datosUsuario.cedula=""
                        this.openSuccess=true
                        this.busy = false
                        this.mostrarUsuarios("1")
                        this.error=false
                        this.getUsuarios()
                    }else{
                        this.openError=true
                        this.errores=err
                        console.log(this.errores);
                        if (this.errores.especialidad_cedula) {
                            console.log("entre");
                            this.errores.especialidad_cedula=["El campo cedula especialidad es obligatorio"]
                        }
                        this.error=true
                        this.busy = false;
                    }

            } catch (error) {
                console.log(error);
            }
        },
        
        async getUsuarios() {
            this.usuarios=[]
            this.loading = true;
            this.p1=[]
            this.p2=[]
            this.p3=[]
            this.p4=[]
            this.p5=[]
            try {
                const res = await fetch(`${url}/api/usuarios`, {
                    method: 'get',
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                        "X-Requested-With": "XMLHttpRequest",
                        "Authorization": "Bearer "+ localStorage.getItem("token")
                    }
                });
                if (!res.ok) {
                    const message = `An error has occured: ${res.status} - ${res.statusText}`;
                    throw new Error(message);
                }
                const data = await res.json();
                // this.usuarios = data.usuarios
                console.log(data);
                for (const val of data.usuarios)
                {
                    if (val.cedula==null) {
                        val.cedula="Sin cedula"
                    }
                    switch (val.perfil.id) {
                        case 1:
                            this.p1.push(val)
                            break;

                        case 2:
                            this.p2.push(val)
                            break;

                        case 3:
                            this.p3.push(val)
                            break;

                        case 4:
                            this.p4.push(val)
                            break;

                        case 5:
                            this.p5.push(val)
                            break;
                    
                        default:
                            break;
                    } 
                }
                this.usuarios=this.p1
                this.loading=false

            } catch (error) {
                console.log(error);
            }
        },
        cerrarModal(){
            this.openSuccess=false
        },
        cerrarModalError(){
            this.openError=false
        },
        cerrarModalDeleteSuccess(){
            this.openDeleteSuccess = false;
        },
        volverAtras(){
            this.showUser = false;
           /*  this.usuarios=this.p1 */
            this.regresarVerUsuarios()
            // this.getUsuarios()
        },
        usuario_eliminado(){
            this.openDeleteSuccess = true;
            this.showUser = false;
            this.usuarios=this.p1
            this.regresarVerUsuarios()
            this.getUsuarios()
        },
        updated(){
            this.getUsuarios();
        }
    },

    mounted() {
        this.obtenerEspecialidades();
        this.obtenerPerfiles()
        this.getUsuarios()
        /* end */

    },
    destroyed() {  
        this.$destroy();
    }
}
</script>

<style scoped>
    .icon-errror {
        color: rgb(60, 59, 59);
    }

    .disabled_select:disabled {
        background-color: white !important;
        color: black !important;
    }

    .size_th_skele {
        width: 100vh;
    }

    .size {
        height: 60px;
         background: linear-gradient(-45deg, #dddddd, #f0f0f0, #dddddd, #f0f0f0);
        background-size: 400% 400%;
        -webkit-animation: skeletonLoading 2.25s ease infinite;
        -moz-animation: skeletonLoading 2.25s ease infinite;
        animation: skeletonLoading 2.25s ease infinite;
    }

    .animacion {
        height: 30px;
        background: linear-gradient(-45deg, #dddddd, #f0f0f0, #dddddd, #f0f0f0);
        background-size: 400% 400%;
        -webkit-animation: skeletonLoading 2.25s ease infinite;
        -moz-animation: skeletonLoading 2.25s ease infinite;
        animation: skeletonLoading 2.25s ease infinite;
    }

     @-webkit-keyframes skeletonLoading {
        0% {
            background-position: 0% 50%;
        }
        50% {
            background-position: 100% 50%;
        }
        100% {
            background-position: 0% 50%;
        }
    }

    @-moz-keyframes skeletonLoading {
        0% {
            background-position: 0% 50%;
        }
        50% {
            background-position: 100% 50%;
        }
        100% {
            background-position: 0% 50%;
        }
    }

    @keyframes skeletonLoading {
        0% {
            background-position: 0% 50%;
        }
        50% {
            background-position: 100% 50%;
        }
        100% {
            background-position: 0% 50%;
        }
    }

    .contenedor {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .cursor {
        cursor: pointer;
    }

    #contenido {
        border-radius: 15px;
        height: auto;
        margin-left: 35px;
        justify-content: center;
        text-align: center;
    }

    #img {
        position: absolute;
        border-radius: 50px;
        width: auto;
        height: 3.1rem;
        margin-top: 4px;
        z-index: 1;
        background: white;
        justify-content: center;
        align-items: center;
    }

    .btn_border {
        color: #689aaf;
        background-color: #fff;
        border: 1px solid #689aaf;
    }

    .push_btn_blue {
        color: white;
        background-color: #689aaf;
    }

    p {
        font-size: 0.8rem;
    }

    i {
        color: #ced4da;
    }
</style>