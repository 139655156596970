<template>
    <div>
        <b-container class="mt-5">
            <b-row>
                <b-col class="mb-3" cols="12" sm="12" md="12" lg="3">
                    <b-button variant="bg_primary" class="btn-option-edit-user py-4 btn-block text-uppercase">Editar</b-button>
                    <b-button @click="abrirModal()" variant="bg_primary" class="btn-option-delete-user mt-3 py-4 btn-block text-uppercase">Eliminar</b-button>
                </b-col>
                <b-col class="px-5" cols="12" sm="12" md="12" lg="9">
                    <h3 class="txt_secondary text-uppercase mb-3">Editar de usuario</h3>
                    <b-table-simple class="table-sm">
                        <b-tbody>  
                            <b-tr>
                                <b-th class="th_style">Nombre</b-th>
                                <b-td class="border_table">
                                    <b-form-input autocomplete="off" id="nombre" :disabled="disabled" v-model="datosUsuario.nombre" :state="nombre" v-on:keypress="soloLetras($event)" class="disabled_inputs border-0" size="sm" placeholder="ingrese el nombre"/>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">Numero de teléfono</b-th>
                                <b-td class="border_table">
                                    <b-form-input autocomplete="off" id="telefono" :disabled="disabled" v-model="datosUsuario.celular" maxlength="10" v-on:keypress="soloNumeros($event)" :state="celular" class="disabled_inputs border-0" size="sm" placeholder="ingrese numero de teléfono celular"/>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">Email</b-th>
                                <b-td class="border_table">
                                    <b-form-input autocomplete="off" id="email" :disabled="disabled" v-model="datosUsuario.email" :state="email" class="disabled_inputs border-0" size="sm" placeholder="ingrese correo"/>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">Perfil de usuario</b-th>
                                <b-td class="border_table">
                                    <b-form-select id="perfil" value-field="id" text-field="nombre" v-model="datosUsuario.perfil_id" :disabled="disabled" :options="perfil_id" class="disabled_inputs border-0" size="sm"/>
                                </b-td>
                            </b-tr>
                            <b-tr  v-if="datosUsuario.perfil_id==3 || datosUsuario.perfil_id==4" >
                                <b-th class="th_style">Cédula general</b-th>
                                <b-td class="border_table">
                                    <b-form-input autocomplete="off" v-model="datosUsuario.cedula" :disabled="disabled" :state="cedula" maxlength="15" minlength="8" class="disabled_inputs border-0" size="sm" placeholder="ingrese cédula"/>
                                </b-td>
                            </b-tr>
                            <b-tr v-if="datosUsuario.perfil_id==3 || datosUsuario.perfil_id==4">
                                <b-th class="th_style">Especialidad</b-th>
                                <b-td class="border_table">
                                    <b-form-select id="especialidad" :disabled="disabled" v-model="datosUsuario.especialidad_id" :options="especialidades" :state="VEspecialidad" class="disabled_inputs border-0" size="sm"/>
                                </b-td>
                            </b-tr>
                            <b-tr v-if="datosUsuario.perfil_id==3 || datosUsuario.perfil_id==4">
                                <b-th class="th_style">Cédula especialidad</b-th>
                                <b-td class="border_table">
                                    <b-form-input v-model="datosUsuario.especialidad_cedula" :state="cedulaEspecialidad" :disabled="disabled" autocomplete="off" class="disabled_inputs border-0" size="sm" placeholder="ingrese cédula de especialidad"/>
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                    <b-row v-if="error">
                            <b-col cols="12">
                            <div class="contenedor-de-errores pt-3 pb-1">
                                <ul class="lista-errores px-2">
                                    <li v-for="(value,key) in errores" :key="key.value"><i class="fa fa-warning px-1" />| {{value[0]}} <br></li>
                                </ul>
                            </div>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
            
            
            <div v-if="btnActualizar" class="d-flex justify-content-end mt-5">
                <span class="fa fa-save fa-lg my-auto px-3 icon_color d-none d-sm-none d-md-none d-lg-block"></span>
                <b-col cols="12" sm="12" md="5" lg="5">
                    <button class="btn_formluario border-0 btn-block py-2 txt_white text-uppercase" v-if="btnActualizar" @click="actualizar()">Actualizar</button>
                </b-col>
            </div>


            <div v-if="btnGuardar" class="d-flex justify-content-end mt-5">
                <span class="fa fa-save fa-lg my-auto px-3 icon_color d-none d-sm-none d-md-none d-lg-block"></span>
                <b-col cols="12" sm="12" md="5" lg="5">
                    <b-overlay
                        :show="busy"
                        rounded
                        opacity="0.6"
                        spinner-small
                        spinner-variant="primary"
                        >
                            <button class="btn_formluario border-0 btn-block py-2 txt_white text-uppercase" v-if="btnGuardar" @click="guardar()">Guardar</button>
                    </b-overlay>
                </b-col>
            </div>

            <div  v-if="btnCancelar" class="d-flex justify-content-end mt-3">
                <span class="fa fa-warning fa-lg my-auto px-3 fa-warning d-none d-sm-none d-md-none d-lg-block"></span>
                <b-col cols="12" sm="12" md="5" lg="5">
                    <button class="btn_cancelar_edicion btn-block py-1 txt_white text-uppercase" @click="cancelar()">Cancelar</button>
                </b-col>
            </div>

            <div v-if="btnBack" class="d-flex justify-content-end mt-3 mb-5 d-none d-sm-block d-md-none d-lg-none">
                    <span class="fa fa-arrow-left fa-lg my-auto px-3 icon_color d-none d-sm-none d-md-none d-lg-block"></span>
                <b-col cols="12" sm="12" md="5" lg="5">
                    <button class="btn-back-border btn-block py-1 txt_white text-uppercase" @click="atras()">Atrás</button>
                </b-col>
            </div>

        </b-container>
        <b-button @click="atras()" variant="btn_red" class="float-btn-back pt-1 d-none d-sm-none d-md-block d-lg-block">
            <span class="fa fa-arrow-left my-float-btn"></span>
        </b-button>

        <b-modal class="rounded-lg" size="sm" v-model="modalEliminar" no-close-on-esc no-close-on-backdrop hide-header-close hide-footer hide-header centered>
            <div class="d-flex justify-content-center">
                <span class="icono text-center p-2" align="center">
                    <i class="fa fa-warning fa-3x"></i>
                </span>
            </div>
            <div class="d-flex justify-content-center mt-2">
                <span><strong>¡Alerta!</strong></span><br>
            </div>
            <div class="d-flex justify-content-center text-center mt-1">
                <p class="subttitle-modal">¿Estás seguro de querer eliminar este usuario?</p>
            </div>
            <div class="d-flex justify-content-center mt-2">
                <b-overlay
                    :show="busyDelete"
                    rounded
                    opacity="0.6"
                    spinner-small
                    spinner-variant="primary"
                >
                    <button @click="eliminar()" :disabled="busyDelete" class="btn-modal-success border-0 px-3 pb-2 pt-1">Eliminar</button>
                </b-overlay>
                <!-- <button class="btn-modal-success border-0 px-3 pb-2 pt-1">Eliminar</button> -->
                <button @click="cerrarModalEliminar()" class="btn-modal-cancel px-3 pb-2 pt-1 ml-4">Cancelar</button>
            </div>
        </b-modal>

        <Error :abrir="modalError" @cerrarModalError="cerrarModalError()"/>
        <Success :abrir="modalSuccess" @cerrarModal="cerrarModal()"/>
    </div>
</template>

<script>
import Error from "@/components/Modals/Error.vue"
import Success from "@/components/Modals/Success.vue"
import { url } from "../../../settings";


export default {
    name: 'VerUsuario',
    components: { Error, Success },

    data: () => ({
        busy: false,
        busyDelete: false,
        btnGuardar: false,
        btnActualizar: true,
        btnCancelar: false,
        btnBack: true,
        error:false,
        errores:[],
        disabled: true,
        modalEliminar: false,
        modalError: false,
        modalSuccess: false,
        especialidades:[],
        datosUsuario: {
            nombre:"",
            email:"",
            celular:"",
            perfil_id: "",
            especialidad_id: "",
            especialidad_cedula: '',
            cedula:"",
            id:""
        },

        perfil_id: [
        {
            "id": 1,
            "nombre": "Administrador/a"
        },
        {
            "id": 2,
            "nombre": "Recepcionista"
        },
        {
            "id": 3,
            "nombre": "Médico"
        },
        {
            "id": 4,
            "nombre": "Enfermero/a"
        },
        ]
    }),

    computed: {
        nombre() {
            return this.datosUsuario.nombre.length >= 10
        },

        celular() {
            return this.datosUsuario.celular.length === 10 ? true : false
        },

        email() {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(this.datosUsuario.email)
        },

        cedulaEspecialidad() {
            let flag = false
            if (this.datosUsuario.especialidad_cedula.length >= 7) {
                flag = true
            }
            return flag
        },

        cedula() {
            let flag = false
            if (this.datosUsuario.cedula.length >= 7 && this.datosUsuario.cedula.length <= 15) {
                flag = true
            }
            return flag
        },

        VEspecialidad() {
            return this.datosUsuario.especialidad_id != null || this.datosUsuario.especialidad_id != ""
        }
    },

    methods: {
        guardar() {
            this.updateUser()
        },

        /* Lo recibo en la vista administrador */
        atras() {
            this.$emit('volverAtras');
        },

        cancelar() {
            this.btnActualizar = true;
            this.disabled = true;
            this.btnGuardar = false;
            this.btnCancelar = false;
        },

        actualizar() {
            this.btnActualizar = false;
            this.btnGuardar = true;
            this.btnCancelar = true;
            this.disabled = false;
        },

        abrirModal() {
            this.modalEliminar = true;
        },
        
         cerrarModalEliminar(){
            this.modalEliminar = false;
        },

        soloNumeros(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },

        soloLetras(evt) {
            let char = String.fromCharCode(evt.keyCode);
            if(/^[A-Za-z-zA-ZÀ-ÿ,\u00f1\u00d1 ]+$/.test(char)) {
                return true;
            } else {
                evt.preventDefault();
            }
        },

        /* Modal */
        eliminar() {
            if (this.datosUsuario.especialidad_id==null) {
                this.delet("delete")
            }else{
                this.delet("edit")
            }
        },

        async delet(key){
            switch (key) {
                case "delete":
                    try {
                        this.busyDelete = true
                        const res = await fetch(`${url}/api/usuarios/destroy/${this.datosUsuario.id}`, {
                            method: 'delete',
                            headers: {
                                'Authorization': "Bearer "+ localStorage.getItem("token"),
                            }
                        });
                        if (!res.ok) {
                            const message = `An error has occured: ${res.status} - ${res.statusText}`;
                            this.busyDelete = false
                            throw new Error(message);
                        }
                        this.busyDelete = false
                        this.modalEliminar=false
                        this.$emit('usuario_eliminado')
                    } catch (error) {
                        console.log(error);
                    }
                    break;
                case "edit":
                    try {
                        this.datosUsuario.especialidad_id=null;
                        const res = await fetch(`${url}/api/usuarios/update/`+this.datosUsuario.id, {
                            method: 'put',
                            headers: {
                            "Access-Control-Allow-Origin": "*",
                            "Content-Type": "application/json",
                            "X-Requested-With": "XMLHttpRequest",
                            'Authorization': "Bearer "+ localStorage.getItem("token"),
                            },
                            body: JSON.stringify(this.datosUsuario),
                        });
                        const data = res.json();
                        console.log(data);
                        var warn=false
                        var promise = Promise.resolve(data);
                        await promise.then(function(val) {
                            if (val.class=="success") {
                            warn=true
                            }
                        });
                        if (warn) {
                            this.delet("delete");
                        }
                    } catch (error) {
                        this.busy = false;
                        console.log(error);
                    }
                    break;
            
                default:
                    break;
            }
            
        },
        /* endModal */

        async updateUser() {
            this.busy = true;
            this.error=false
            this.errores=[]
            try {
                const res = await fetch(`${url}/api/usuarios/update/`+this.datosUsuario.id, {
                    method: 'put',
                    headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                    "X-Requested-With": "XMLHttpRequest",
                    'Authorization': "Bearer "+ localStorage.getItem("token"),
                    },
                    body: JSON.stringify(this.datosUsuario),
                });
                const data = res.json();
                console.log(data);
                var warn=false
                var promise = Promise.resolve(data);
                var err=[]
                await promise.then(function(val) {
                    if (val.class=="success") {
                    warn=true
                    }else{
                        err=val.errors
                    }
                });
                if (warn) {
                    this.$emit("updated")
                    this.modalSuccess = true;
                    this.busy = false;
                    this.cancelar()
                }else{
                    this.errores=err
                    this.error=true
                    this.modalError=true
                    this.busy = false;
                }
            } catch (error) {
                this.error=false
                this.busy = false;
                console.log(error);
            }
        },

        async obtenerEspecialidades() {
            try {
                const res = await fetch(`${url}/api/especialidades`, {
                    method: 'get',
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                        "X-Requested-With": "XMLHttpRequest",
                        "Authorization": "Bearer "+ localStorage.getItem("token")
                    }
                });
                if (!res.ok) {
                    const message = `An error has occured: ${res.status} - ${res.statusText}`;
                    throw new Error(message);
                }

                const data = await res.json();
                this.especialidades = data.especialidades.map(item => {
                    return {
                        value: item.id,
                        text: item.nombre
                    };
                });
                this.especialidades.unshift( { value: "", text: 'Sin especialidad' },)
                console.log(this.especialidades);
            } catch (error) {
                console.log(error);
            }
        },
        // event $on
        cerrarModal(){
            this.modalSuccess = false;
        },
        cerrarModalError(){
            console.log('cerrarModalError');
            this.modalError = false;
        },
        DatosUsuario(datos){
            this.datosUsuario.nombre = datos.nombre
            this.datosUsuario.id = datos.id
            this.datosUsuario.email = datos.email
            this.datosUsuario.celular = datos.celular
            this.datosUsuario.perfil_id = datos.perfil.id
            
            try {
                if (this.datosUsuario.perfil_id==3||this.datosUsuario.perfil_id==4) {
                    this.datosUsuario.cedula = datos.cedula
                    this.datosUsuario.especialidad_id = datos.especialidad.id
                    this.datosUsuario.especialidad_cedula = datos.especialidad.cedula
                }
                
            } catch (error) {
                console.log(error);
            }
        }
    },

    mounted() {
        this.obtenerEspecialidades()
    }


}
</script>

<style scoped>
    .btn-back-border {
        border: 1px solid #367894;
        color: #367894;
        background-color: white;
        border-radius: 25px;
        outline:none !important;
        outline-width: 0 !important;
        box-shadow: none;
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
    }
    
    .fa-warning {
        color: #d15460;
    }

    .btn_cancelar_edicion {
        background-color: white;
        border: 2px solid #c9888e;
        color: #c9888e;
        border-radius: 25px;
        outline:none !important;
        outline-width: 0 !important;
        box-shadow: none;
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
    }

    .disabled_inputs:disabled {
        background-color: white !important;
        color: #a1a1a1 !important;
    }
</style>